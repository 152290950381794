import "./navbar.scss";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import Offcanvas from 'react-bootstrap/Offcanvas';


const Navbar = ({ show, setShow }) => {
  const { login, logout } = useAuth();
  const { account } = useWeb3React();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (setShow) {
      setShow(true);
    }
  };
  // const trustWallet = async () => {
  //   if (account) {
  //     await logout("walletconnect");
  //     localStorage.clear();
  //   } else {
  //     try {
  //       let a =  login("walletconnect");
  //       console.log("Aaaaaaa", a)
  //       handleClose()
  //       localStorage.setItem('connectorId', 'walletconnect');
  //       localStorage.setItem("flag", "true");
  //       return (a)
  //     } catch (err) {
  //       console.log("eerr", err)
  //     }
  //   }
  // };
  const trustWallet = async () => {
    if (account) {
      await logout("walletconnect");
      localStorage.clear();
    } else {
      try {
         login("walletconnect"); 
        handleClose();
        localStorage.setItem('connectorId', 'walletconnect');
        localStorage.setItem("flag", "true");
      } catch (err) {
        console.log("eerr", err);
      }
    }
  };
  
  const tomi = async () => {
    if (account) {
      await logout("walletconnect");
      localStorage.clear();
    } else {
      login("walletconnect");
      handleClose()
      localStorage.setItem('connectorId', 'walletconnect');
      localStorage.setItem("flag", "true");
    }
  };
  // console.log("acc", account)
  // const tomiPayWallet = async () => {
  //   if (account) {
  //     await logout("walletconnect1");
  //     localStorage.clear();
  //   } else {
  //     login("walletconnect1");
  //     handleClose()
  //     localStorage.setItem('connectorId', 'walletconnect');
  //     localStorage.setItem("flag", "true");
  //   }
  // };


  const connectMetaMask1 = async () => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      handleClose();


    }
  };

  const clearData = () => {
    localStorage.removeItem("connectorId");
    localStorage.removeItem("flag");
  };


  const [showw, setShoww] = useState(false);
  const handleClosew = () => setShoww(false);
  const handleShoww = () => setShoww(true);
  const TomiPayBol = localStorage.getItem('IsTomipay')
  const providerNull = localStorage.getItem('provider')
  // console.log("payyy", TomiPayBol)

  // for tomi variable

//   useEffect(() => {
//     // tomiPayWallet()
//     // alert(TomiPayBol)

// if(!account)
    
//     {  // console.log("in hool",a)
//       if (TomiPayBol == 'true') {
//         // alert(`providerNull=${providerNull}`)
//         trustWallet();
//         // alert(`TomiPayBol=${TomiPayBol}`)
//         if (providerNull=='true') {
//           setShow(true)
//         }
//       }}
    

//   }, [TomiPayBol,providerNull])



  return (
    <>
      <section className="main-navbar">
        <div className="custom-container">
          <nav class="navbar navbar-expand-lg p-0">
            <a class="navbar-brand" href="https://dop.org/">
              <img src="\logo.svg" alt="img" className="img-fluid" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              onClick={handleShoww}
            >
              <span class="navbar-toggler-icon">
                <img
                  src="\assets\navbar-bar.svg"
                  alt="img"
                  className="img-fluid"
                />
              </span>
            </button>
            <div
              class="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a href="/buy" class="nav-link active" aria-current="page">
                    Buy
                  </a>
                </li>
                <li class="nav-item">
                  <a href="/claim" class="nav-link">
                    Claim
                  </a>
                </li>
                {/* <li class="nav-item">
                  <a href="/ClaimNft" class="nav-link">
                    NFT
                  </a>
                </li> */}
              </ul>
              <a href="https://dop.org/" className="connect-btn me-2">
                go to dop.org
              </a>
              {!account ? (
                <button className="connect-btn" onClick={handleShow}>
                  Connect Wallet
                </button>
              ) : (
                <button className="dissconnect-btn" onClick={connectMetaMask1}>
                  Disconnect
                </button>
              )}
            </div>
          </nav>
        </div >
      </section >

      <Modal
        className="connectwallet-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="twice-btns" >
            <button className="forhideee" onClick={connectMetaMask1}>
              <img src="\assets\metamask.svg" alt="img" className="img-fluid" />
              <h6>Metamask</h6>
            </button>
            {/* {TomiPayBol == 'true' ?
              <button onClick={trustWallet}>
                <img
                  src="\assets\tomiPAYLogo.png"
                  alt="img"
                  className="img-fluid payyy"
                />
                <h6>tomiPAY</h6>
              </button>
              : */}
              <button onClick={trustWallet}>
                <img
                  src="\assets\walletconnect.svg"
                  alt="img"
                  className="img-fluid"
                />
                <h6>WalletConnect</h6>
              </button>

            {/* } */}

          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas className="mobile-navbar" show={showw} onHide={handleClosew}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <img src="\logo.svg" alt="img" className="img-fluid" /> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <a href="/buy">Buy</a>
          <a href="/claim">Claim</a>
          {/* <a href="/ClaimNft">NFT</a> */}
          <div className="btnss">
            {/* {TomiPayBol ?
              '' : */}
            <>
              {!account ? (
                <button className="connect-btn" onClick={() => {
                  handleClosew();
                  handleShow();
                }}>
                  Connect Wallet
                </button>
              ) : (
                <button className="dissconnect-btn" onClick={connectMetaMask1}>
                  Disconnect
                </button>
              )}
            </>
            {/* } */}
            <a href="https://dop.org/" className="connect-btn mt-2 w-100">
              go to dop.org
            </a>
          </div>

        </Offcanvas.Body>
        <img
          src="\assets\mblviewbg.png"
          alt="img"
          className="img-fluid mblviewbanner-bg"
        />
      </Offcanvas>
    </>
  );
};

export default Navbar;
