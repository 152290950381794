import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/Login/Login';
import Claim from './components/Claim/Claim';
import useEagerConnect from "./hooks/useEagerConnect";
import ClaimNft from './components/Claim/ClaimNft';
import axios from "axios";
import { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
function App() {
  useEagerConnect();
  const [showservice, setShowService] = useState(false);
  const handleCloseService = () => setShowService(false);
  const handleShowService = () => setShowService(true);
  const get_ApiKeys = async () => {
    try {
      const response = await axios({
        method: "get",
        url: "https://ip.nf/me.json",
      });
      //  console.log(response?.data,'response',response?.data.ip.country?.toLowerCase());
      // localStorage.setItem('ip', response?.data.ip.ip)
      // localStorage.setItem('country', response?.data.ip.country?.toLowerCase())
      if (response?.data.ip.country?.toLowerCase() === 'iran' || response?.data.ip.country?.toLowerCase() === 'north korea' || response?.data.ip.country?.toLowerCase() === 'iraq' || response?.data.ip.country?.toLowerCase() === 'yemen' || response?.data.ip.country?.toLowerCase() === 'syria' || response?.data.ip.country?.toLowerCase() === 'lebanon' || response?.data.ip.country?.toLowerCase() === 'northkorea' || response?.data.ip.country?.toLowerCase() === 'central african republic' || response?.data.ip.country?.toLowerCase() === 'belarus' || response?.data.ip.country?.toLowerCase() === 'burundi' || response?.data.ip.country?.toLowerCase() === 'centralafricanrepublic' || response?.data.ip.country?.toLowerCase() === 'congo' || response?.data.ip.country?.toLowerCase() === 'Guinea' || response?.data.ip.country?.toLowerCase() === 'guinea-bissau' || response?.data.ip.country?.toLowerCase() === 'guineabissau' || response?.data.ip.country?.toLowerCase() === 'libya' || response?.data.ip.country?.toLowerCase() === 'mali' || response?.data.ip.country?.toLowerCase() === 'myanmar' || response?.data.ip.country?.toLowerCase() === 'burma' || response?.data.ip.country?.toLowerCase() === 'republicofsouthsudan' || response?.data.ip.country?.toLowerCase() === 'republic of south sudan' || response?.data.ip.country?.toLowerCase() === 'russia' || response?.data.ip.country?.toLowerCase() === 'somalia' || response?.data.ip.country?.toLowerCase() === 'sudan' || response?.data.ip.country?.toLowerCase() === 'ukraine' || response?.data.ip.country?.toLowerCase() === 'venezuela' || response?.data.ip.country?.toLowerCase() === 'zimbabwe') {
        setShowService(true)
      }
      else {
        setShowService(false)
      }

    } catch (error) {
      setShowService(false)
      console.log(error, 'error');
      // localStorage.setItem('ip',response?.data.ip)
      // localStorage.setItem('country',response?.data.country)
      // throw error; 
    }
  };
  useEffect(() => {
    get_ApiKeys()
  }, [])

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <ToastContainer autoClose={5000} style={{ fontSize: 12, fontWeight: 300 }} theme="light" position="top-center" />
      {/* <Router>
            <Switch>
              <Route exact path='/' component={Login} />
              <Route exact path='/buy' component={Landing} />
              <Route exact path='/claim' component={Claim} />
              <Route exact path='/ClaimNft' component={ClaimNft} />
            </Switch>
          </Router> */}

      <Modal className="service-modal" backdrop="static"
        keyboard={false} show={showservice} onHide={handleCloseService} centered>
        <Modal.Body>
          <div className="upper-div">
            <img src="\ic_round-warning.svg" alt="img" className="img-fluid" />
            <h6>Service Unavailable</h6>
          </div>
          <div className="bottom-div">
            <h6>The Service is Unavailable in Your Region</h6>
            <p>We’re sorry, but the service you are trying to access is not available in your Country.</p>
          </div>
        </Modal.Body>
      </Modal>


      <Modal backdrop={false} keyboard={false} className='set-new-for-closed' show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Important Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>DOP private sale last round is exclusively on Gems</p>
          <a href="https://privatesale.gems.vip/" className='btn-new-set'>Go to <img src="/gems.svg" alt="img" className='img-fluid' /></a>
        </Modal.Body>
      </Modal>

      <div className="new-sectionforclosing">
      <img src="\bg-img.png" alt="img" className='img-fluid bg-img' />
        <div className="custom-container">
          <div className="top-navbar">
            <a href="/"><img src="\logo.svg" alt="img" className='img-fluid' /></a>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
